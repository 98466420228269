var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('img',{attrs:{"src":_vm.logo,"height":"45px","draggable":"false"}})]),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",style:({
        backgroundImage: 'url(' + _vm.imgUrl + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }),attrs:{"lg":"8"}},[(_vm.announcements)?_c('div',{staticClass:"w-100"},[(_vm.announcements.length)?_c('h2',[_vm._v(_vm._s(_vm.$t("Duyurular")))]):_vm._e(),_vm._l((_vm.announcements),function(announcement){return _c('b-alert',{key:announcement.id,staticClass:"mt-2 p-1",attrs:{"variant":announcement.alert_variant,"show":""}},[_c('b',[_vm._v(_vm._s(_vm.$i18n.locale == "de" ? announcement.title_de : announcement.title_en))]),_c('p',{staticClass:"mt-1",domProps:{"innerHTML":_vm._s(_vm.$i18n.locale == 'de' ? announcement.content_de : announcement.content_en)}})])})],2):_vm._e()]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg p-lg-3",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"mx-auto login-locale",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('div',{staticClass:"mb-5 text-center",staticStyle:{"list-style-type":"none"}},[_c('locale')],1),_c('b-card-title',{staticClass:"mb-1 font-weight-bold",attrs:{"title-tag":"h2"}},[_vm._v(" "+_vm._s(_vm.$t("app_title"))+" ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("login_subtitle"))+" ")]),_c('b-alert',{attrs:{"variant":"primary","show":""}},[_c('div',{staticClass:"alert-body font-small-2"},[_c('a',{on:{"click":function($event){_vm.userEmail = 'admin@verigut.de';
                _vm.password = '12345678';}}},[_c('small',{staticClass:"mr-50"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("Admin"))+":")]),_vm._v(" admin@verigut.de | 12345678")])]),_c('br'),_c('a',{on:{"click":function($event){_vm.userEmail = 'customer@verigut.de';
                _vm.password = '12345678';}}},[_c('small',{staticClass:"mr-50"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("Customer"))+":")]),_vm._v(" customer@verigut.de | 12345678")])]),_vm._v(" >"),_c('br'),_c('a',{on:{"click":function($event){_vm.userEmail = 'employee@verigut.de';
                _vm.password = '12345678';}}},[_c('small',{staticClass:"mr-50"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("Employee"))+":")]),_vm._v(" employee@verigut.de | 12345678")])]),_c('br'),_c('a',{on:{"click":function($event){_vm.userEmail = 'manager@verigut.de';
                _vm.password = '12345678';}}},[_c('small',{staticClass:"mr-50"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("Manager"))+":")]),_vm._v(" manager@verigut.de | 12345678")])]),_vm._v(" >"),_c('br'),_c('a',{on:{"click":function($event){_vm.userEmail = 'hr@verigut.de';
                _vm.password = '12345678';}}},[_c('small',{staticClass:"mr-50"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("Human Resources"))+":")]),_vm._v(" hr@verigut.de | 12345678")])])]),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(_vm.$t('login_clue')),expression:"$t('login_clue')",modifiers:{"hover":true,"left":true}}],staticClass:"position-absolute",staticStyle:{"top":"10","right":"10"},attrs:{"icon":"HelpCircleIcon","size":"18"}})],1),_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"login-email"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Email'),"vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-email","state":errors.length > 0 ? false : null,"name":"login-email","placeholder":_vm.$t('Email')},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v(_vm._s(_vm.$t("Şifre")))]),_c('b-link',{attrs:{"to":{ name: 'auth-forgot-password' }}},[_c('small',[_vm._v(_vm._s(_vm.$t("Şifremi Unuttum")))])])],1),_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":_vm.$t('Şifre')},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"remember-me","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" "+_vm._s(_vm.$t("Beni Hatırla"))+" ")])],1),(!_vm.loading)?_c('b-button',{staticClass:"auth-button",attrs:{"type":"submit","variant":"null","block":"","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t("Giriş Yap"))+" ")]):_vm._e(),(_vm.loading)?_c('b-button',{staticClass:"auth-button",attrs:{"disabled":"","block":"","variant":"null"}},[_c('b-spinner',{attrs:{"small":""}}),_vm._v(" "+_vm._s(_vm.$t("Yükleniyor"))+"... ")],1):_vm._e()],1)]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v(_vm._s(_vm.$t("Hesabınız yok mu?")))]),_c('b-link',{attrs:{"to":{ name: 'auth-register' }}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("Kayıt Ol")))])])],1),_c('p',{staticClass:"clearfix mb-0"},[_c('span',{staticClass:"d-block d-md-inline-block mt-25 float-right"},[_c('span',{staticClass:"d-inline-block mt-2",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.$t("Powered by"))+" "),_c('a',{attrs:{"href":"https://peop.de"}},[_vm._v("PEOP")])])])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }